import { api } from "./rtkApi";

export const appApi = api.injectEndpoints({
	endpoints: (build) => ({
		getAllSouvenirs: build.query({
			query: () => {
				return {
					method: "GET",
					url: `/souvenir/all`,
				};
			},
		}),
		getSouvenirById: build.query({
			query: ({ id }) => {
				return {
					method: "GET",
					url: `/souvenir/id/${id}`,
				};
			},
		}),
		getSouvenirBySlug: build.query({
			query: ({ slug }) => {
				return {
					method: "GET",
					url: `/souvenir/slug/${slug}`,
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetAllSouvenirsQuery,
	useLazyGetAllSouvenirsQuery,
	useGetSouvenirByIdQuery,
	useLazyGetSouvenirByIdQuery,
	useGetSouvenirBySlugQuery,
	useLazyGetSouvenirBySlugQuery,
} = appApi;
