import { api } from "./rtkApi";

export const eventsApi = api.injectEndpoints({
	endpoints: (build) => ({
		getEvents: build.query({
			query: () => {
				return {
					method: "GET",
					url: `/evenement/list`,
				};
			},
		}),
		getEvent: build.query({
			query: ({ slug }) => {
				return {
					method: "GET",
					url: `/evenement/get/${slug}`,
				};
			},
		}),
		getEventDates: build.query({
			query: ({ id }) => {
				return {
					method: "GET",
					url: `/evenement/dates/${id}`,
				};
			},
		}),
		getEventDate: build.query({
			query: ({ idDate }) => {
				return {
					method: "GET",
					url: `/evenement/get-date/${idDate}`,
				};
			},
		}),
	}),
	overrideExisting: true,
});

export const {
	useGetEventsQuery,
	useLazyGetEventsQuery,
	useGetEventQuery,
	useLazyGetEventQuery,
	useGetEventDatesQuery,
	useLazyGetEventDatesQuery,
	useGetEventDateQuery,
	useLazyGetEventDateQuery,
} = eventsApi;
