import React, { useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import { CenteredSiteBlock } from "./Home";
import { useIsMobile, useIsTablet } from "../hooks/responsive";

import { useLazyGetEventDatesQuery, useLazyGetEventQuery } from "../store/api/event";
import ProductCategoryChip from "../components/basket/ProductCategoryChip";
import MainTitle from "../components/home/MainTitle";

import backgroundImage from "../resources/images/png/events/img.png";
import EventDateAndPlaceCard from "../components/event/EventDateAndPlaceCard";
import EventPartnerCard from "../components/event/EventPartner";
import EventDate from "../components/event/EventDate";

import { ReactComponent as FlowersBG } from "../resources/images/svg/event/backgroundFlowers.svg";
import EventExplorers from "../components/event/EventExplorers";
import EventPresentation from "../components/event/EventPresentation";

import eventBgImage from "../resources/images/png/events/backgroundEventPresentation.png";
import EventPracticalInfo from "../components/event/EventPracticalInfo";

const Event = ({ ...props }) => {
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const theme = useTheme();

	const [event, setEvent] = useState(null);
	const [eventDates, setEventDates] = useState(null);

	const [getEventQuery] = useLazyGetEventQuery();
	const [getEventDatesQuery] = useLazyGetEventDatesQuery();
	// const [getEventProduct] = useLazyGetProductQuery();

	// const { data: souvenirsData } = useGetAllSouvenirsQuery();

	const navigate = useNavigate();

	const params = useParams();

	React.useEffect(() => {
		const { slug } = params;
		if (slug) {
			getEventQuery({ slug }).then((response) => {
				if (!response.isError) {
					setEvent({
						...response.data,
						intervenants: JSON.parse(response?.data?.intervenants),
						partenariat:
							response?.data?.partenariat && response?.data?.partenariat !== ""
								? JSON.parse(response?.data?.partenariat)
								: null,
					});
					getEventDatesQuery({ id: response.data.id }).then((response) => {
						if (!response.isError) {
							setEventDates(
								response?.data.dates.map((date) => ({ ...date, lieux: date.lieux !== "" ? JSON.parse(date.lieux) : {} })),
							);
						} else {
							console.error(response);
						}
					});
					// if (response.data.idboutique) {
					// 	getSouvenirProduct({ id: response.data.idboutique }).then((response) => {
					// 		if (!response.isError) {
					// 			setProduct(response.data.product);
					// 		} else {
					// 			// there is an error.
					// 			// navigate("/");
					// 			console.error(response);
					// 		}
					// 	});
					// }
				} else {
					// there is an error.
					// navigate("/");
					console.error(response);
				}
			});
		}
	}, [params]);

	React.useEffect(() => {
		console.log(event);
	}, [event]);

	if (!event) return null;

	return (
		<Box
			sx={{
				display: "flex",
				overflow: "hidden",
				position: "relative",
				alignItems: "center",
				justifyContent: "center",
				flexDirection: "column",
				width: "100%",
				background: "#18122D",
			}}
		>
			<CenteredSiteBlock
				container
				sx={{
					position: "relative",
				}}
			>
				<Box
					sx={{
						position: "absolute",
						top: 0,
						left: 0,
						height: "100%",
						width: "100%",
						[theme.breakpoints.up("lg")]: {
							background: "linear-gradient(268.98deg, rgba(5, 4, 10, 0) 15%, #05040A 35%, #18122D 100%)",
						},
						[theme.breakpoints.down("lg")]: {
							background: "linear-gradient(268.98deg, rgba(5, 4, 10, 0) 25%, #05040A 50%, #18122D 100%)",
						},
						[theme.breakpoints.down("md")]: {
							background: "linear-gradient(268.98deg, rgba(5, 4, 10, 0) 30%, #05040A 55%, #18122D 100%)",
						},
						[theme.breakpoints.down("sm")]: {
							background: "linear-gradient(268.98deg, rgba(5, 4, 10, 0) 40%, #05040A 60%, #18122D 100%)",
						},
						zIndex: 1,
					}}
				/>
				<Grid
					item
					spacing={2}
					sx={{
						display: "flex",
						alignItems: "flex-start",
						justifyContent: "flex-start",
						flexDirection: "column",
						zIndex: 2,
						padding: (theme) => `${theme.spacing(5)} ${theme.spacing(3)}`,
					}}
				>
					<ProductCategoryChip
						name={"Évènement - Conférence"}
						color={"white"}
						sx={{
							marginBottom: 2,
						}}
					/>
					<Box
						sx={{
							marginBottom: 2,
						}}
					>
						<MainTitle
							title={event.titre}
							typoProps={{
								applyTransform: false,
								underlineColor: theme.palette.primary.main,
								sx: {
									color: "white",
								},
							}}
						/>
					</Box>
					<Typography
						dangerouslySetInnerHTML={{ __html: event.description }}
						sx={{
							fontSize: 16,
							fontFamily: "Branding Medium",
							color: "white",
							[theme.breakpoints.up("lg")]: {
								width: "50%",
							},
							[theme.breakpoints.down("lg")]: {
								width: "50%",
							},
							[theme.breakpoints.down("md")]: {
								width: "60%",
							},
							[theme.breakpoints.down("sm")]: {
								width: "70%",
							},
						}}
					/>
					<Box
						sx={{
							marginTop: 2,
						}}
					>
						<EventDateAndPlaceCard eventDates={eventDates} />
					</Box>
					<Box
						sx={{
							marginTop: 2,
						}}
					>
						<EventPartnerCard partner={event.partenariat} />
					</Box>
				</Grid>
				<Box
					sx={{
						position: "absolute",
						top: 0,
						right: 0,
						marginLeft: "auto",
						display: "flex",
						height: "100%",
					}}
				>
					<img
						src={`https://medias.inrees.com/img/agenda/haut_${event?.id}.jpg`}
						alt={"backgroundImage"}
						style={{
							objectFit: "contain",
							[theme.breakpoints.up("sm")]: {
								width: "100%",
							},
							[theme.breakpoints.down("sm")]: {
								objectFit: "cover",
							},
							height: "100%",
						}}
					/>
					<div
						style={{
							position: "absolute",
							top: 0,
							right: 0,
						}}
					/>
				</Box>
			</CenteredSiteBlock>
			{eventDates && eventDates.length > 0 && (
				<Box
					sx={{
						background: "white",
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						position: "relative",
					}}
				>
					<CenteredSiteBlock>
						<FlowersBG style={{ position: "absolute", top: 0, left: 0, opacity: 0.07 }} />
						<Grid
							container
							sx={{
								padding: 2,
							}}
						>
							<Grid
								item
								xs={12}
								sx={{
									display: "flex",
									alignItems: "center",
									justifyContent: "center",
									flexDirection: "column",
								}}
							>
								<MainTitle
									title={"Réservez vos billets"}
									typoProps={{
										applyTransform: false,
										sx: {},
									}}
								/>
								<Typography fontFamily={"Branding Medium"} mt={1}>
									Entrée uniquement sur réservation. Aucune place disponible à la vente le soir de cet
									évènement. Aucune liste d'attente lors de cette soirée.
								</Typography>
							</Grid>
							{(eventDates ?? []).map((date) => (
								<EventDate key={date.id} event={event} date={date} />
							))}
						</Grid>
					</CenteredSiteBlock>
				</Box>
			)}
			<Box
				sx={{
					background: theme.palette.secondary.dark,
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					position: "relative",
				}}
			>
				<CenteredSiteBlock>
					<EventExplorers event={event} />
				</CenteredSiteBlock>
			</Box>
			<Box
				sx={{
					background: `url(${eventBgImage})`,
					backgroundSize: "100% 100%",
					width: "100%",
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
					flexDirection: "column",
					position: "relative",
				}}
			>
				<Box
					sx={{
						background: (theme) => theme.palette.secondary.main + "BB",
						width: "100%",
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<CenteredSiteBlock
						sx={{
							maxWidth: "1200px",
						}}
					>
						<EventPresentation event={event} />
					</CenteredSiteBlock>
				</Box>
				<CenteredSiteBlock
					sx={{
						maxWidth: "1200px",
					}}
				>
					<EventPracticalInfo event={event} />
				</CenteredSiteBlock>
			</Box>
		</Box>
	);
};

export default Event;
