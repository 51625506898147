import React from "react";
import { Box, Grid, Typography, useTheme } from "@mui/material";
import moment from "moment";
import { DateRangeRounded, RoomRounded, TodayRounded } from "@mui/icons-material";
import { ReactComponent as WebcamIcon } from "../../resources/images/svg/event/webcam.svg";
import eventDate from "./EventDate";

const EventDateAndPlaceCard = ({ eventDates }) => {
	const theme = useTheme();

	const firstDate = React.useMemo(() => {
		let first = null;
		if (!eventDates) return null;
		eventDates.forEach((date) => {
			const tmp = moment.unix(date.dateDebut);
			if (!first || first.isAfter(date)) {
				first = tmp;
			}
		});
		return first;
	}, [eventDates]);

	const lastDate = React.useMemo(() => {
		let last = null;
		if (!eventDates) return null;
		eventDates.forEach((date) => {
			const tmp = moment.unix(date.dateFin);
			if (!last || last.isBefore(date)) {
				last = tmp;
			}
		});
		return last;
	}, [eventDates]);

	const isSameDay = React.useMemo(() => {
		return firstDate && lastDate && firstDate?.format("LL") === lastDate?.format("LL");
	}, [firstDate, lastDate]);

	const isVisio = React.useMemo(() => {
		if (!eventDates) return false;
		return eventDates.some((date) => {
			return date.lieux.visio === true;
		});
	}, [eventDates]);

	const isPhysical = React.useMemo(() => {
		if (!eventDates) return false;
		return eventDates.some((date) => {
			return date.lieux.visio !== true;
		});
	}, [eventDates]);

	const place = React.useMemo(() => {
		if (!eventDates || eventDates.length < 1) return null;
		return eventDates[0].lieux;
	}, [eventDates]);

	if (!firstDate || !lastDate) return null;

	return (
		<Grid
			container
			sx={{
				backgroundColor: "#C7C4DE",
				display: "flex",
				alignItems: "center",
				minWidth: "50vw",
				padding: (theme) => theme.spacing(3),
				[theme.breakpoints.up("sm")]: {
					paddingRight: (theme) => theme.spacing(10),
				},
				[theme.breakpoints.down("sm")]: {
					gap: 3,
					padding: (theme) => theme.spacing(2),
				},
			}}
		>
			<Grid
				item
				xs={12}
				sm={6}
				sx={{
					display: "flex",
					alignItems: "center",
					gap: 2,
					flexDirection: "row",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						borderRadius: "50%",
						width: "54px",
						height: "54px",
						backgroundColor: (theme) => theme.palette.secondary.main,
						padding: (theme) => theme.spacing(2),
					}}
				>
					<TodayRounded
						sx={{
							color: "white",
							width: "30px",
							height: "30px",
						}}
					/>
				</Box>
				{!isSameDay ? (
					<Typography
						fontFamily={"Branding Bold"}
						fontSize={"18px"}
					>{`Du ${firstDate.format("LL[ ]HH[h]mm")} au ${lastDate.format("LL[ ]HH[h]mm")}`}</Typography>
				) : (
					<Typography
						fontFamily={"Branding Bold"}
						fontSize={"18px"}
					>{`Le ${firstDate.format("LL")}, de ${firstDate.format("HH[h]mm")} à ${lastDate.format("HH[h]mm")}`}</Typography>
				)}
			</Grid>
			<Grid
				item
				xs={12}
				sm={6}
				sx={{
					display: "flex",
					alignItems: "center",
					gap: 2,
					flexDirection: "row",
				}}
			>
				<Box
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
						borderRadius: "50%",
						width: "54px",
						height: "54px",
						backgroundColor: (theme) => theme.palette.secondary.main,
						padding: (theme) => theme.spacing(2),
					}}
				>
					{isVisio ? (
						<WebcamIcon
							style={{
								color: "white",
								width: "30px",
								height: "30px",
							}}
						/>
					) : (
						<RoomRounded
							sx={{
								color: "white",
								width: "30px",
								height: "30px",
							}}
						/>
					)}
				</Box>
				{isVisio && isPhysical ? (
					<Typography
						fontFamily={"Branding Bold"}
						fontSize={"18px"}
					>{`Événements physiques et en ligne`}</Typography>
				) : isVisio ? (
					<Typography fontFamily={"Branding Bold"} fontSize={"18px"}>{`Uniquement en visio`}</Typography>
				) : (
					<Typography fontFamily={"Branding Bold"} fontSize={"18px"}>
						{!place
							? `${eventDates.length} conférence${eventDates.length > 1 ? "s" : ""}`
							: `${place.adresse}, ${place.ville}, ${place.cp} ${place.pays}`}
					</Typography>
				)}
			</Grid>
		</Grid>
	);
};

export default EventDateAndPlaceCard;
