import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import MainTitle from "../home/MainTitle";

import imageEvent from "../../resources/images/png/events/img.png";
import InexCard from "../InexCard";

const EventPracticalInfo = ({ event }) => {
	const theme = useTheme();

	return (
		<Grid container p={3}>
			<InexCard
				sx={{
					padding: (theme) => theme.spacing(5),
					paddingTop: (theme) => theme.spacing(5),
					paddingBottom: (theme) => theme.spacing(5),
					marginBottom: (theme) => theme.spacing(5),
					boxShadow: "none",
				}}
			>
				<Typography
					sx={{
						fontSize: 20,
						fontFamily: "Branding Bold",
						marginBottom: 5,
					}}
				>
					{"Informations pratiques"}
				</Typography>
				<Typography
					dangerouslySetInnerHTML={{ __html: event.informations }}
					sx={{
						fontSize: 18,
						fontFamily: "Branding Medium",
					}}
				/>
			</InexCard>
		</Grid>
	);
};

export default EventPracticalInfo;
