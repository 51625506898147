import React from "react";
import InexCard from "../InexCard";
import { Box, IconButton, Skeleton, Typography } from "@mui/material";
import PriceFormater from "../PriceFormater";
import { ShoppingBagOutlined } from "@mui/icons-material";
import { useLazyGetProductQuery } from "../../store/api/product";

const SouvenirCard = ({ souvenir, ...props }) => {
	const loading = React.useMemo(() => {
		return !souvenir;
	}, [souvenir]);

	const [getSouvenirProduct] = useLazyGetProductQuery();
	const [product, setProduct] = React.useState(null);

	React.useEffect(() => {
		if (souvenir?.idboutique) {
			getSouvenirProduct({ id: souvenir?.idboutique }).then((response) => {
				if (!response.isError) {
					setProduct(response.data.product);
				} else {
				}
			});
		}
	}, [souvenir]);

	return (
		<a href={`/souvenir?id=${souvenir?.id}`} style={{ textDecoration: "none" }}>
			<InexCard
				onClick={() => {}}
				sx={{
					width: "100%",
					padding: 0,
					paddingTop: 0,
					paddingBottom: 0,
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					<Box
						sx={{
							width: "100%",
							aspectRatio: 1,
						}}
					>
						{loading ? (
							<Skeleton variant={"rectangular"} width={"100%"} height={"100%"} />
						) : (
							<img
								src={`https://medias.inrees.com//img/divers/${souvenir?.id}_mini.jpg`}
								alt="souvenirImage"
								style={{ width: "100%", objectFit: "contain", aspectRatio: 1 }}
							/>
						)}
					</Box>
					<Box
						sx={{
							display: "flex",
							flexDirection: "column",
							padding: (theme) => theme.spacing(2),
						}}
					>
						<Box
							sx={{
								width: "100%",
							}}
						>
							{loading ? (
								<Skeleton variant={"rectangular"} width={"100%"} height={"15px"} />
							) : (
								<Typography>{souvenir.titre}</Typography>
							)}
						</Box>
						<Box
							sx={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "space-between",
								alignItems: "flex-end",
								width: "100%",
							}}
						>
							{product && (
								<PriceFormater
									price={product.prix}
									withTaxes={false}
									withTitle={false}
									withTimeFrame={false}
								/>
							)}
							{!(loading || souvenir.idboutique === 0 || !product) && (
								<a href={`/panier?id=${product.id}`}>
									<IconButton
										sx={{
											marginLeft: 1,
											backgroundColor: "transparent",
											display: "flex",
											alignItems: "center",
											justifyContent: "center",
											width: "45px",
											height: "45px",
											border: (theme) => `1px solid ${theme.palette.secondary.main}`,
											"& > svg": {
												color: (theme) => theme.palette.secondary.main,
											},
											"&:hover": {
												backgroundColor: (theme) => theme.palette.secondary.main,
												border: "none",
												"& > svg": {
													color: "white",
												},
											},
										}}
									>
										<ShoppingBagOutlined
											sx={{
												marginTop: "-2px",
												color: (theme) => theme.palette.secondary.main,
											}}
										/>
									</IconButton>
								</a>
							)}
						</Box>
					</Box>
				</Box>
			</InexCard>
		</a>
	);
};

export default SouvenirCard;
