import React from "react";
import {
	Alert,
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	Tooltip,
	Typography,
	useTheme,
} from "@mui/material";
import moment from "moment";
import { InfoOutlined, ShoppingBagOutlined } from "@mui/icons-material";
import PriceFormater from "../PriceFormater";
import { CTAButton } from "../home/blocks/odyssee/OdysseeCard";
import ChangeQuantityComponent from "../basket/ChangeQuantityComponent";

import { ReactComponent as TicketMiddleIcon } from "../../resources/images/svg/event/ticketMiddle.svg";
import InexCard from "../InexCard";

import backgroundImage from "../../resources/images/png/events/img.png";
import { useLazyGetProductQuery } from "../../store/api/product";
import { useSelector } from "react-redux";
import { getUser } from "../../store/reducers/authSlice";
import { calculateProductPrice } from "../basket/BasketProductCard";

const SubscribeButton = ({ subscriptionKey = undefined, isSubscribed }) => {
	const indexesInUrl = React.useMemo(() => {
		switch (subscriptionKey) {
			case "digital":
				return [1];
			case "tv":
				return [2];
			case "mag":
				return [3];
			default:
				return [2, 3];
		}
	}, [subscriptionKey]);

	return (
		<a
			href={
				!isSubscribed
					? `https://abonnements.inexplore.com/step/subscription?selectedAbos=[${indexesInUrl}]`
					: "#"
			}
			aria-disabled={isSubscribed}
		>
			<CTAButton
				text={isSubscribed ? "Déjà abonné" : "Je m'abonne"}
				textWithGradient={false}
				disableBackground={true}
				disabled={isSubscribed}
				sx={{
					flexDirection: "row-reverse",
					color: (theme) => (!isSubscribed ? theme.palette.secondary.main : theme.palette.grey[500]),
					backgroundColor: (theme) => (!isSubscribed ? theme.palette.yellow : theme.palette.grey[500]),
					"& > p": {
						backgroundColor: "transparent",
					},
				}}
			/>
		</a>
	);
};

const EventDate = ({ event, date }) => {
	const theme = useTheme();

	const date1 = React.useMemo(() => {
		if (date) return moment.unix(date.dateDebut);
		return null;
	}, [date]);

	const date2 = React.useMemo(() => {
		if (date) return moment.unix(date.dateFin);
		return null;
	}, [date]);

	const isSameDay = React.useMemo(() => {
		return date1 && date2 && date1?.format("LL") === date2?.format("LL");
	}, [date1, date2]);

	const [quantity, setQuantity] = React.useState(1);

	const hasProduct = React.useMemo(() => {
		return date?.idproduit && date?.idproduit !== 0;
	}, [date]);

	const [getProductQuery] = useLazyGetProductQuery({ id: date.idproduit });
	const [product, setProduct] = React.useState(null);

	React.useEffect(() => {
		if (hasProduct && date) {
			getProductQuery({ id: date.idproduit }).then((result) => {
				if (!result.isError && result.data?.product) setProduct(result.data.product);
			});
		}
	}, [date, hasProduct]);

	const maxPromo = React.useMemo(() => {
		const idx = ["reduct_tv", "reduct_mag"];
		return idx.reduce((acc, key) => acc + (product?.[key] ?? 0), 0);
	}, [product]);

	const user = useSelector(getUser);
	const [totalPrice, totalPromo, totalPriceBeforePromo] = React.useMemo(() => {
		if (!product) return [0, 0, 0];
		return calculateProductPrice(product, user);
	}, [product, user]);

	const [showDialog, setShowDialog] = React.useState(false);

	const place = React.useMemo(() => {
		console.log(date?.lieux);
		return date?.lieux;
	}, [date]);

	const addressUrl = React.useMemo(() => {
		if (!place) return "#";
		return `https://www.google.com/maps/place/${place.adresse}+${place.ville}+${place.cp}+${place.pays}`.replaceAll(
			" ",
			"+",
		);
	}, [place]);

	if (!event || !date) return null;

	let rows = [];
	const nowverif = new Date().getTime() / 1000;
	if (!user || (!(user["digital"] > nowverif) && !(user["tv"] > nowverif) && !(user["mag"] > nowverif))) {
		rows = [
			{
				name: "Inexploré TV + digital + magazine",
				subKey: undefined,
				key: undefined,
				price: 12.9,
				promo: maxPromo,
			},
			{
				name: "Inexploré TV + digital",
				subKey: "tv",
				key: "reduct_tv",
				price: 9.9,
				promo: product?.reduct_tv,
			},
			{
				name: "Inexploré Magazine",
				subKey: "mag",
				key: "reduct_mag",
				price: 3.0,
				promo: product?.reduct_mag,
			},
			{
				name: "Inexploré digital",
				subKey: "digital",
				key: "reduct_digit",
				price: 2.9,
				promo: product?.reduct_digit,
			},
		];
	} else {
		if (!(user["tv"] > nowverif)) {
			if (!(user["mag"] > nowverif)) {
				rows.push({
					name: "Inexploré TV + digital + magazine",
					subKey: undefined,
					key: undefined,
					price: 12.9,
					promo: maxPromo,
				});
				rows.push({
					name: "Inexploré TV + digital",
					subKey: "tv",
					key: "reduct_tv",
					price: 9.9,
					promo: product?.reduct_tv,
				});
				rows.push({
					name: "Inexploré magazine",
					subKey: "mag",
					key: "reduct_mag",
					price: 3.0,
					promo:
						user["digital"] > nowverif ? product?.reduct_digit + product?.reduct_mag : product?.reduct_mag,
				});
			} else {
				rows.push({
					name: "Inexploré TV + digital",
					subKey: "tv",
					key: "reduct_tv",
					price: 9.9,
					promo: product?.reduct_tv + product?.reduct_mag,
				});
				if (!(user["digital"] > nowverif)) {
					rows.push({
						name: "Inexploré digital",
						subKey: "digital",
						key: "reduct_digit",
						price: 2.9,
						promo: product?.reduct_digit + product?.reduct_mag,
					});
				}
			}
		} else {
			if (!(user["mag"] > nowverif)) {
				rows.push({
					name: "Inexploré magazine",
					subKey: "mag",
					key: "reduct_mag",
					price: 3.0,
					promo: product?.reduct_mag,
				});
			}
		}
	}

	return (
		<Grid
			item
			container
			mt={3}
			mb={3}
			xs={12}
			sx={{
				display: "flex",
				flexDirection: "row",
				alignItems: "center",
				justifyContent: "center",
				gap: 5,
			}}
		>
			<Grid
				item
				sx={{
					display: "flex",
					alignItems: "center",
					justifyContent: "center",
				}}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "column",
						borderRadius: "30px",
						filter: "drop-shadow(0 0 20px #162A414A)",
					}}
				>
					<InexCard
						sx={{
							maxWidth: 240,
							paddingTop: 1.5,
							paddingBottom: 1.5,
							padding: 1.5,
							borderTopLeftRadius: "30px",
							borderTopRightRadius: "30px",
							overflow: "hidden",
							boxShadow: "none",
						}}
					>
						<img
							src={
								hasProduct && product
									? `https://medias.inrees.com/img/produit/moyen_${product.id}.jpg`
									: backgroundImage
							}
							alt={"eventImage"}
							style={{
								objectFit: "contain",
								width: "100%",
								borderTopLeftRadius: "20px",
								borderTopRightRadius: "20px",
							}}
						/>
					</InexCard>
					<TicketMiddleIcon />
					<InexCard
						sx={{
							maxWidth: 240,
							paddingTop: 1,
							paddingBottom: 2.5,
							padding: 2.5,
							borderBottomLeftRadius: "30px",
							borderBottomRightRadius: "30px",
							overflow: "hidden",
							boxShadow: "none",
						}}
					>
						<Typography
							sx={{
								fontFamily: "Branding Medium",
								fontSize: "14px",
							}}
						>
							{"Conférence"}
						</Typography>
						<Typography
							sx={{
								fontFamily: "Yesevaone",
								fontSize: "21px",
								lineHeight: "1.4em",
								marginBottom: (theme) => theme.spacing(0.5),
							}}
						>
							{event?.titre}
						</Typography>
					</InexCard>
				</Box>
			</Grid>
			<Grid item>
				<Typography
					sx={{
						fontFamily: "Yesevaone",
						fontSize: "37px",
					}}
				>
					{event.titre}
				</Typography>
				{place.visio === true && (
					<Typography
						sx={{
							fontFamily: "Branding Medium",
						}}
					>
						{!isSameDay
							? `En visio du ${date1.format("LL[ ]HH[h]mm")} au ${date2.format("LL[ ]HH[h]mm")}`
							: `En visio le ${date1.format("LL")}, de ${date1.format("HH[h]mm")} à ${date2.format("HH[h]mm")}`}
					</Typography>
				)}
				{place.visio !== true && place.adresse !== "" && (
					<Typography
						sx={{
							fontFamily: "Branding Medium",
						}}
					>
						<a
							href={addressUrl}
							target={"_blank"}
							style={{
								color: theme.palette.primary.main,
								textDecoration: "none",
								fontFamily: "Branding SemiBold",
							}}
						>{`${place.adresse}, ${place.ville}, ${place.cp} ${place.pays}`}</a>
						<br />
						{!isSameDay
							? `du ${date1.format("LL[ ]HH[h]mm")} au ${date2.format("LL[ ]HH[h]mm")}`
							: `le ${date1.format("LL")}, de ${date1.format("HH[h]mm")} à ${date2.format("HH[h]mm")}`}
					</Typography>
				)}
				{hasProduct && product && (
					<Box
						sx={{
							display: "flex",
							flexDirection: "row",
							alignItems: "flex-end",
						}}
					>
						<Tooltip title={totalPromo > 0 ? "Réduction octroyée grâce à vos abonnements inexploré" : ""}>
							<PriceFormater
								priceProps={{
									sx: {
										fontFamily: "Branding Bold",
										fontWeight: 700,
										textDecoration: totalPromo > 0 ? "line-through" : undefined,
										fontSize: totalPromo > 0 ? "30px" : "41px",
										color: (theme) => (totalPromo > 0 ? theme.palette.primary.main : undefined),
									},
								}}
								withTitle={false}
								withTimeFrame={false}
								price={totalPriceBeforePromo * 100}
							/>
						</Tooltip>
						{totalPromo > 0 && (
							<PriceFormater
								priceProps={{
									sx: { fontSize: 41, fontFamily: "Branding Bold", fontWeight: 700, marginLeft: 1 },
								}}
								withTitle={false}
								withTimeFrame={false}
								price={totalPrice * 100}
							/>
						)}
					</Box>
				)}
				{maxPromo > 0 && (
					<Box mt={1}>
						<Alert
							severity={"info"}
							icon={
								<InfoOutlined
									sx={{
										color: (theme) => theme.palette.secondary.main,
										marginLeft: 1,
										marginRight: 1,
									}}
								/>
							}
							sx={{
								backgroundColor: "rgba(109,53,144,0.1)",
								padding: 2,
							}}
						>
							<Typography>
								{rows.length > 0
									? `Vous pouvez bénéficier de ${(maxPromo / 100).toFixed(2).replace(".", ",")}€ de remise sur cet événement avec nos abonnements `
									: ` Vous bénéficiez du meilleur prix grâce à vos abonnements `}
							</Typography>
							{rows.length > 0 && (
								<Typography
									href={"#"}
									sx={{
										color: theme.palette.primary.main,
										textDecoration: "underline",
										"&:hover": {
											cursor: "pointer",
										},
									}}
									onClick={() => {
										setShowDialog(true);
									}}
								>
									{`en savoir plus`}
								</Typography>
							)}
						</Alert>
						<Dialog
							open={showDialog}
							maxWidth="lg"
							onClose={() => {
								setShowDialog(false);
							}}
						>
							<DialogTitle>Bénéficiez de réductions grâce à nos abonnements !</DialogTitle>
							<DialogContent>
								<Table sx={{ minWidth: 650 }} aria-label="simple table">
									<TableHead>
										<TableRow>
											<TableCell>Abonnement</TableCell>
											<TableCell align="right">Réduction</TableCell>
											<TableCell align="right">Nouveau prix</TableCell>
											<TableCell align="center"></TableCell>
										</TableRow>
									</TableHead>
									<TableBody
										sx={{
											fontFamily: "Branding Medium",
										}}
									>
										{rows.map((row) => {
											const prixAfterPromo = product.prix - (row.key ? row.promo : maxPromo);
											//const promo = row.key ? product[row.key] : maxPromo;
											const promo = row.key ? row.promo : maxPromo;

											const now = new Date().getTime() / 1000;
											const isSubscribed = !user ? false : user[row.subKey] > now;
											const isSubToAll = !user ? false : user["tv"] > now && user["mag"] > now;

											return (
												<TableRow
													key={row.name}
													sx={{
														background: (theme) =>
															isSubscribed || isSubToAll
																? theme.palette.grey[200]
																: undefined,
														"&:last-child td, &:last-child th": {
															border: 0,
														},
													}}
												>
													<TableCell
														component="th"
														scope="row"
														sx={{
															fontFamily: "Branding SemiBold",
														}}
													>
														{row.name}{" "}
														<p>{`Pour seulement ${row.price} € en plus par mois`} </p>
													</TableCell>
													<TableCell align="right">{`${(promo / 100).toFixed(2).replace(".", ",")}€`}</TableCell>
													<TableCell
														align="right"
														sx={{
															color: (theme) => theme.palette.primary.main,
															fontFamily: "Branding Bold",
														}}
													>{`${(prixAfterPromo / 100).toFixed(2).replace(".", ",")}€`}</TableCell>
													<TableCell align="center">
														<SubscribeButton
															subscriptionKey={row.subKey}
															isSubscribed={isSubscribed || isSubToAll}
														/>
													</TableCell>
												</TableRow>
											);
										})}
									</TableBody>
								</Table>
								<Box mt={2}>
									<Typography variant={"caption"}>
										Les réduction se cumulent avec vos abonnements, sortez de cette modal pour voir
										le prix final.
									</Typography>
								</Box>
							</DialogContent>
							<DialogActions>
								<Button
									onClick={() => {
										setShowDialog(false);
									}}
								>
									Fermer
								</Button>
							</DialogActions>
						</Dialog>
					</Box>
				)}
				{hasProduct && product && (
					<Box
						mt={2}
						sx={{
							display: "flex",
						}}
					>
						<ChangeQuantityComponent
							quantity={quantity}
							stockInformation={null}
							onPlusClicked={() => {
								if (quantity <= date.qteMax) setQuantity(quantity + 1);
							}}
							onMinusClicked={() => {
								if (quantity > 1) setQuantity(quantity - 1);
							}}
						/>
					</Box>
				)}
				<Box mt={2}>
					<a href={hasProduct && product ? `/panier?id=${date.idproduit}&quantity=${quantity}` : "#"}>
						<CTAButton
							variant={"texticon"}
							icon={
								<ShoppingBagOutlined
									sx={{
										fontSize: "26px",
										color: (theme) =>
											hasProduct && product
												? theme.palette.secondary.main
												: theme.palette.grey[300],
									}}
								/>
							}
							textWithGradient={false}
							disableBackground={true}
							text={"Ajouter au panier"}
							disabled={!hasProduct || !product}
							sx={{
								padding: 3,
								marginRight: 2,
								flexDirection: "row-reverse",
								color: (theme) => theme.palette.secondary.main,
								backgroundColor: (theme) =>
									hasProduct && product ? theme.palette.yellow : theme.palette.grey[500],
								"& > p": {
									backgroundColor: "transparent",
									color: (theme) => (hasProduct && product ? undefined : theme.palette.grey[300]),
								},
							}}
						/>
					</a>
				</Box>
			</Grid>
		</Grid>
	);
};

export default EventDate;
