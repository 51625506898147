import React from "react";
import { Box, Chip } from "@mui/material";

const ProductCategoryChip = ({ name, color = "#182B43", ...props }) => {
	return (
		<Box {...props}>
			<Chip
				label={name}
				variant="outlined"
				sx={{
					borderRadius: 0,
					color: color,
					borderColor: color,
					textTransform: "uppercase",
				}}
			/>
		</Box>
	);
};

export default ProductCategoryChip;
