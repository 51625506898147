import React from "react";
import { Grid, Typography, useTheme } from "@mui/material";
import MainTitle from "../home/MainTitle";

import imageEvent from "../../resources/images/png/events/img.png";

const EventPresentation = ({ event }) => {
	const theme = useTheme();

	return (
		<Grid container p={3}>
			<Grid item xs={12} mb={5}>
				<MainTitle
					title={"Présentation de l'évènement"}
					typoProps={{
						applyTransform: false,
						underlineColor: theme.palette.primary.main,
						sx: {
							color: "white",
						},
					}}
				/>
			</Grid>
			<Grid
				container
				item
				xs={12}
				spacing={4}
				sx={{
					display: "flex",
					alignItems: "center",
				}}
			>
				<Grid item xs={12} md={8} mb={5}>
					<Typography
						dangerouslySetInnerHTML={{ __html: event.presentation }}
						sx={{
							fontSize: 18,
							fontFamily: "Branding Medium",
							color: "white",
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={4}
					mb={5}
					sx={{
						display: "flex",
						alignItems: "center",
						justifyContent: "center",
					}}
				>
					<img
						src={imageEvent}
						alt={"imageEvent"}
						style={{ width: "100%", objectFit: "contain", maxWidth: "350px" }}
					/>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default EventPresentation;
