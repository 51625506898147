import React from "react";
import { Box, Chip, Grid, IconButton, Typography, useTheme } from "@mui/material";
import MainTitle from "../home/MainTitle";
import { Swiper, SwiperSlide } from "swiper/react";

import { ReactComponent as ExplorerBG } from "../../resources/images/svg/event/explorerBG.svg";
import { KeyboardArrowLeftRounded, KeyboardArrowRightRounded } from "@mui/icons-material";
import { useIsMobile } from "../../hooks/responsive";

const Explorer = ({ intervenant }) => {
	const explorerName = React.useMemo(() => {
		return intervenant.prenom + " " + intervenant.nom;
	}, [intervenant]);

	return (
		<Box
			sx={{
				position: "relative",
				width: "280px",
				height: "322px",
				background: "#1F17237D",
				overflow: "hidden",
				borderRadius: "30px",
				boxShadow: "0px 0px 25px 0px #1F17237D",
				zIndex: 1,
			}}
		>
			<ExplorerBG style={{ position: "absolute", bottom: "0", width: "100%", zIndex: -1 }} />
			<Box
				sx={{
					zIndex: 2,
				}}
			>
				<img
					src={intervenant.image}
					alt="intervenantImage"
					style={{ width: "100%", objectFit: "cover", height: "100%" }}
				/>
			</Box>
			{intervenant.tag && intervenant.tag !== "" && (
				<Chip
					sx={{
						position: "absolute",
						top: (theme) => theme.spacing(2.5),
						left: (theme) => theme.spacing(2.5),
						zIndex: 3,
						backgroundColor: (theme) => theme.palette.lightgrey,
						fontSize: "14px",
					}}
					label={intervenant.tag}
				/>
			)}
			<Box
				sx={{
					display: "flex",
					alignItems: "flex-start",
					justifyContent: "flex-end",
					flexDirection: "column",
					position: "absolute",
					paddingBottom: (theme) => theme.spacing(2.5),
					paddingLeft: (theme) => theme.spacing(2.5),
					paddingRight: (theme) => theme.spacing(2.5),
					bottom: 0,
					width: "100%",
					height: "50%",
					background: "linear-gradient(180deg, rgba(23, 15, 33, 0) 0%, #170F21 100%)",
				}}
			>
				<Typography sx={{ color: "white", fontFamily: "Branding Bold", fontSize: "18px" }}>
					{explorerName}
				</Typography>
				<Typography sx={{ color: "#FBF0E599", fontFamily: "Branding Medium", fontSize: "14px" }}>
					{intervenant.profession}
				</Typography>
			</Box>
		</Box>
	);
};

const EventExplorers = ({ event }) => {
	const theme = useTheme();
	const isMobile = useIsMobile();

	const swiperRef = React.useRef(null);
	const boxRef = React.useRef(null);
	const [slidesPerView, setSlidesPerView] = React.useState(null);
	const [progress, setProgress] = React.useState(0);

	React.useLayoutEffect(() => {
		if (boxRef.current) {
			const onWindowSizeChanged = () => {
				const rect = boxRef.current.getBoundingClientRect();
				let tmp = parseInt(rect.width / 300);
				if (rect.width % 300 < 100) tmp -= 1;
				setSlidesPerView(Math.max(tmp, 1));
			};
			onWindowSizeChanged();
			window.addEventListener("resize", onWindowSizeChanged);
			return () => {
				window.removeEventListener("resize", onWindowSizeChanged);
			};
		}
	}, [boxRef.current, event]);

	const handleGoNext = React.useCallback(() => {
		if (swiperRef.current) {
			swiperRef.current.slideNext();
		}
	}, [swiperRef.current]);

	const handleGoPrevious = React.useCallback(() => {
		if (swiperRef.current) {
			swiperRef.current.slidePrev();
		}
	}, [swiperRef.current]);

	const canGoNext = React.useMemo(() => {
		const count = event.intervenants?.length ?? 1;
		return progress < 1 && slidesPerView < count;
	}, [progress, slidesPerView, event]);

	const canGoPrev = React.useMemo(() => {
		return progress > 0;
	}, [progress]);

	return (
		<Grid container p={3}>
			<Grid item xs={12} mb={5}>
				<MainTitle
					title={"Les explorateurs de cet événement"}
					typoProps={{
						applyTransform: false,
						underlineColor: theme.palette.primary.main,
						sx: {
							color: "white",
						},
					}}
				/>
			</Grid>
			<Grid
				item
				xs={12}
				ref={(element) => {
					if (element && !boxRef.current) {
						boxRef.current = element;
					}
				}}
				mt={4}
				mb={6}
			>
				<Box
					sx={{
						display: "flex",
						flexDirection: "row",
						alignItems: "center",
						width: "100%",
						maxWidth: "100%",
						position: "relative",
					}}
				>
					{!isMobile && (
						<IconButton
							onClick={handleGoPrevious}
							disabled={!canGoPrev}
							sx={{
								position: "absolute",
								backgroundColor: (theme) => theme.palette.background.paper,
								top: "50%",
								left: 0,
								transform: "translateY(-50%)",
								marginLeft: 1,
								marginRight: 1,
								color: (theme) => theme.palette.secondary.main,
								zIndex: 1000,
								"&:hover": {
									color: (theme) => theme.palette.background.paper,
									backgroundColor: (theme) => theme.palette.secondary.main,
								},
							}}
						>
							<KeyboardArrowLeftRounded
								sx={{
									fontSize: "35px",
								}}
							/>
						</IconButton>
					)}
					<Box
						sx={{
							width: "100%",
							maxWidth: "100%",
							overflowX: isMobile ? "visible" : "auto",
							scrollbarWidth: "none",
							padding: theme.spacing(3),
							paddingRight: isMobile ? undefined : `calc(51px + ${theme.spacing(2)})`,
							paddingLeft: isMobile ? undefined : `calc(51px + ${theme.spacing(2)})`,
						}}
					>
						<Swiper
							spaceBetween={theme.spacing(3)}
							slidesPerView={slidesPerView}
							centerInsufficientSlides={!isMobile}
							centeredSlides={isMobile}
							pagination={{ clickable: true }}
							scrollbar={{ draggable: true }}
							style={{
								overflow: "visible",
							}}
							onSlideChange={(swiper) => {
								setProgress(swiper.progress);
							}}
							onSwiper={(swiper) => {
								swiperRef.current = swiper;
							}}
						>
							{event.intervenants.map((intervenant, index) => {
								return (
									<SwiperSlide
										key={intervenant.id}
										virtual={index}
										style={{
											height: "auto",
										}}
									>
										<Explorer key={intervenant.id} intervenant={intervenant} />
									</SwiperSlide>
								);
							})}
						</Swiper>
					</Box>
					{!isMobile && (
						<IconButton
							onClick={handleGoNext}
							disabled={!canGoNext}
							sx={{
								position: "absolute",
								backgroundColor: (theme) => theme.palette.background.paper,
								top: "50%",
								right: 0,
								transform: "translateY(-50%)",
								marginLeft: 1,
								marginRight: 1,
								color: (theme) => theme.palette.secondary.main,
								zIndex: 1,
								"&:hover": {
									color: (theme) => theme.palette.background.paper,
									backgroundColor: (theme) => theme.palette.secondary.main,
								},
							}}
						>
							<KeyboardArrowRightRounded
								sx={{
									fontSize: "35px",
								}}
							/>
						</IconButton>
					)}
				</Box>
			</Grid>
		</Grid>
	);
};

export default EventExplorers;
