import React, { useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useGetAllSouvenirsQuery, useLazyGetSouvenirByIdQuery } from "../store/api/souvenir";
import { Box, Divider, Grid, styled, Typography } from "@mui/material";
import { CenteredSiteBlock } from "./Home";
import { useLazyGetProductQuery } from "../store/api/product";
import PriceFormater from "../components/PriceFormater";
import { CTAButton } from "../components/home/blocks/odyssee/OdysseeCard";
import { ShoppingBagOutlined } from "@mui/icons-material";
import ProductCategoryChip from "../components/basket/ProductCategoryChip";
import { useIsMobile, useIsTablet } from "../hooks/responsive";

import snowFlake from "../resources/images/svg/souvenir/snowFlake.svg";
import sunShape from "../resources/images/svg/souvenir/sunShape.svg";
import flower from "../resources/images/svg/souvenir/flower.svg";
import treeLeaf from "../resources/images/svg/souvenir/treeLeaf.svg";
import SouvenirCard from "../components/souvenir/SouvenirCard";
import InexButton from "../components/InexButton";

const Title = styled(Typography)(({ theme, ...props }) => {
	return {
		fontFamily: "Branding Bold",
		fontSize: "50px",
		color: theme.palette.secondary.main,
	};
});

const Subtitle = styled(Typography)(({ theme, ...props }) => {
	return {
		fontFamily: "Branding Bold",
		fontSize: "28px",
		color: theme.palette.secondary.main,
	};
});

const ProductsSectionTitle = styled(Typography)(({ theme, ...props }) => {
	return {
		fontFamily: "Branding Bold",
		fontSize: "31px",
		color: theme.palette.secondary.main,
	};
});

const Souvenir = ({ ...props }) => {
	const isMobile = useIsMobile();
	const isTablet = useIsTablet();
	const [searchParams] = useSearchParams();
	const [souvenir, setSouvenir] = useState(null);
	const [product, setProduct] = useState({ prix: 2900, id: 1 });
	const [productCategory, setProductCategory] = useState({ name: "Les essentiels d'inexploré", id: 1 });

	const [getSouvenirById] = useLazyGetSouvenirByIdQuery();
	const [getSouvenirProduct] = useLazyGetProductQuery();
	// const [getTheme] = useLazyGet();

	const { data: souvenirsData } = useGetAllSouvenirsQuery();

	const navigate = useNavigate();

	React.useEffect(() => {
		const id = searchParams.get("id");
		if (id) {
			getSouvenirById({ id }).then((response) => {
				if (!response.isError) {
					setSouvenir(response.data);
					if (response.data.idboutique) {
						getSouvenirProduct({ id: response.data.idboutique }).then((response) => {
							if (!response.isError) {
								setProduct(response.data.product);
							} else {
								// there is an error.
								// navigate("/");
								console.error(response);
							}
						});
					}
				} else {
					// there is an error.
					// navigate("/");
					console.error(response);
				}
			});
		}
	}, []);

	const backgroundShapes = React.useMemo(() => {
		return isMobile
			? [snowFlake, treeLeaf, flower, sunShape, snowFlake, treeLeaf, flower, sunShape]
			: [snowFlake, treeLeaf, flower, sunShape];
	}, [isMobile]);

	const [page, setPage] = React.useState(1);
	const souvenirs = React.useMemo(() => {
		if (!souvenirsData) return [null, null, null, null];
		return souvenirsData.filter((s) => s.idboutique > 0 /*&& s.id !== souvenir?.id*/).filter((s, i) => i < page * 4);
	}, [souvenir, souvenirsData, page]);

	if (!souvenir) return null;

	// TODO : ThemeProvider for local dark / light mode
	// TODO : redirect to Home on error of api
	return (
		<Box
			sx={{
				display: "flex",
				overflow: "hidden",
				position: "relative",
				alignItems: "center",
				justifyContent: "center",
				width: "100%",
				padding: (theme) => `${theme.spacing(5)} ${theme.spacing(3)}`,
			}}
		>
			<CenteredSiteBlock container spacing={4}>
				<Grid item xs={12} md={4.5}>
					<img
						src={souvenir.imgM}
						alt={"souvenir.image"}
						style={{
							width: "100%",
							objectFit: "contain",
							maxHeight: isMobile || isTablet ? 400 : undefined,
						}}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					md={7.5}
					mt={4}
					sx={{
						display: "flex",
						flexDirection: "column",
					}}
				>
					{productCategory && (
						<ProductCategoryChip
							name={productCategory?.name}
							sx={{
								marginBottom: 2,
							}}
						/>
					)}
					<Title>{souvenir.titre}</Title>
					<Divider
						color={"secondary"}
						sx={{
							marginTop: 2,
							marginBottom: 2,
						}}
					/>
					<Typography
						dangerouslySetInnerHTML={{ __html: souvenir.description }}
						sx={{
							fontSize: 16,
							fontFamily: "Branding Medium",
						}}
					/>
					{product && (
						<>
							<PriceFormater
								price={product.prix}
								withTitle={false}
								withTaxes={false}
								withTimeFrame={false}
								sx={{
									marginTop: 2,
								}}
								priceProps={{
									sx: { fontSize: "40px" },
								}}
							/>
							<Box
								sx={{
									marginTop: 2,
								}}
							>
								<a href={`/panier?id=${product.id}`}>
									<CTAButton
										variant={"texticon"}
										icon={
											<ShoppingBagOutlined
												sx={{
													fontSize: "26px",
													color: (theme) => theme.palette.secondary.main,
												}}
											/>
										}
										textWithGradient={false}
										disableBackground={true}
										text={"Ajouter au panier"}
										sx={{
											padding: 3.5,
											marginRight: 2,
											flexDirection: "row-reverse",
											backgroundColor: (theme) => theme.palette.yellow,
										}}
									/>
								</a>
							</Box>
						</>
					)}
				</Grid>
				<Grid
					mt={!isMobile && !isTablet ? 4 : 0}
					container
					item
					spacing={4}
					xs={12}
					sx={{
						position: "relative",
					}}
				>
					{!isMobile && !isTablet && (
						<Box
							sx={{
								zIndex: -1,
								position: "absolute",
								top: 0,
								left: 0,
								width: isMobile ? "100%" : "90%",
								height: "100%",
								background: "#ffffffbb",
							}}
						/>
					)}
					<Grid p={2} pb={!isMobile && !isTablet ? 10 : 0} item xs={12} md={6}>
						<Subtitle>{souvenir.sst}</Subtitle>
						<Typography
							dangerouslySetInnerHTML={{ __html: souvenir.texte }}
							sx={{
								marginTop: 2,
								fontSize: 16,
								fontFamily: "Branding Medium",
							}}
						/>
					</Grid>
					<Grid
						item
						xs={12}
						md={6}
						sx={{
							display: "flex",
							alignItems: "center",
							height: !isMobile && !isTablet ? "100%" : undefined,
						}}
					>
						<img
							src={souvenir.imgH}
							alt={"souvenir.image"}
							style={{ width: "100%", objectFit: "contain" }}
						/>
					</Grid>
				</Grid>
				{souvenirs.length > 0 && (
					<Grid container item xs={12} spacing={2}>
						<Grid item xs={12}>
							<ProductsSectionTitle>Nos meilleurs produits</ProductsSectionTitle>
						</Grid>
						{souvenirs.map((souvenir, index) => {
							return (
								<Grid item xs={6} sm={4} md={3} key={"souvenir" + index}>
									<SouvenirCard souvenir={souvenir} />
								</Grid>
							);
						})}
						<Box
							sx={{
								width: "100%",
								display: "flex",
								justifyContent: "center",
							}}
						>
							<InexButton
								onClick={() => {
									setPage(page + 1);
								}}
								textWithGradient={false}
								disableBackground={true}
								text={"Voir plus"}
								sx={{
									marginTop: 4,
									padding: 3.5,
									width: "250px",
									backgroundColor: (theme) => theme.palette.lightgrey,
									transition: "border-color 0.3s, background 0.3s, color 0.3s,",
									"& > p": {
										color: (theme) => theme.palette.secondary.main,
										transition: "color 0.3s,",
									},
									"&:hover": {
										backgroundColor: (theme) => theme.palette.secondary.main,
										border: "none",
										"& > p": {
											color: "white",
										},
									},
								}}
							/>
						</Box>
					</Grid>
				)}
			</CenteredSiteBlock>
			{backgroundShapes.map((svg, index) => {
				return (
					<Box
						key={svg + index}
						sx={{
							position: "absolute",
							maxWidth: "40%",
							top: () => {
								const sideIndex = Math.floor(index / 2) + 1;
								return Math.floor(index / 2) * 800 + sideIndex * (index % 2 ? 100 : 0);
							},
							[(index + 1) % 2 ? "left" : "right"]: 0,
							pointerEvents: "none",
							zIndex: -1000,
						}}
					>
						<img
							src={svg}
							alt={svg}
							style={{
								width: "100%",
								objectFit: "contain",
							}}
						/>
					</Box>
				);
			})}
		</Box>
	);
};

export default Souvenir;
