import React, { useState } from "react";
import { Box, Grid, Typography } from "@mui/material";
import moment from "moment";
import { DateRangeRounded, TodayRounded } from "@mui/icons-material";
import { ReactComponent as WebcamIcon } from "../../resources/images/svg/event/webcam.svg";

const EventPartnerCard = ({ partner }) => {
	const [imgErr, setImgErr] = useState(false);

	if (!partner) return null;

	return (
		<Box
			sx={{
				display: "flex",
				color: "white",
				alignItems: "center",
				gap: 2,
			}}
		>
			<Typography>{`En partenariat avec : ${partner.nom}`}</Typography>
			{partner.image && !imgErr && (
				<img
					src={partner.image}
					alt={"partner.image"}
					style={{ width: "40px", height: "40px", borderRadius: "50%" }}
					onLoad={() => setImgErr(false)}
					onError={() => setImgErr(true)}
				/>
			)}
		</Box>
	);
};

export default EventPartnerCard;
